<template>
<div class="bg-white p-2 rounded-md mt-2">
  <DataTable :value="respuestas.rows"
             class="p-datatable-customers p-datatable-sm text-sm"
             showGridlines
             dataKey="id"
             v-model:filters="filters1"
             filterDisplay="menu"
             responsiveLayout="scroll"
             removableSort
  >
    <template #empty>
      No hay datos para mostrar
    </template>

    <Column headerStyle="width: 3rem" style="text-align: center; justify-content: center;" field="fullname" header="Id">
      <template #body="{data}">
        <p class="text-xs">
          {{ data.id }}
        </p>
      </template>
    </Column>

    <Column field="observacion" header="Observaciones" style="min-width:10rem">
      <template #body="{data}">
        <p class="text-xs">
          {{ data.observacion }}
        </p>
      </template>
    </Column>

    <Column field="tipo" header="Tipo" style="min-width:10rem">
      <template #body="{data}">
        <p class="text-xs">
          {{ $h.capitalizeFirstLetter(data.tipoName) }}
        </p>
      </template>
      <template #filter="{filterModel}">
        <Dropdown v-model="filterModel.value" :options="tipos" optionLabel="name" class="p-column-filter" optionValue="code" placeholder="Tiene?" />
      </template>
    </Column>

    <Column field="createdAt" header="Fecha Respuesta" style="min-width:10rem">
      <template #body="{data}">
        <p class="text-xs">
          {{ $h.formatDate(data.createdAt) }}
        </p>
      </template>
    </Column>
    <Column field="createdBy" header="Creado Por" style="min-width:10rem" :showFilterMatchModes="false">
      <template #body="{data}">
        <p class="text-xs">
          {{ data.createdByName }}
        </p>
      </template>
      <template #filter="{filterModel}">
        <div class="mb-3 font-bold">Seleccione</div>
        <Dropdown v-model="filterModel.value" :options="usuarios" optionLabel="fullName" optionValue="id" placeholder="Cualquiera" :filter="true" />
      </template>
    </Column>
    <Column header="Acciones" style="width: 10rem">
      <template #body="{data}">
        <div class="flex gap-4 justify-center">
          <Button icon="pi pi-file-pdf" @click="openModal(data.id)" class="p-button-rounded p-button-info" />
          <!-- <Button icon="pi pi-eye" class="p-button-rounded p-button-help" @click="ver(data)" /> -->
          <!-- <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning" @click="onEditar(data)" /> -->
          <!-- <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="remove(data)" /> -->
        </div>
      </template>
    </Column>
    <Dialog header="Adjuntos" v-model:visible="displayModal" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}" :modal="true">
      <div v-for="(adjunto, i) in adjuntos" :key="i">
        <div class="flex justify-between">
          <div>
            <p>
              {{ adjunto.fileName }}
            </p>
          </div>
          <div>
            <button @click="verPdf(adjunto.urlPath)">
              <i class="pi pi-eye text-blue-600"></i>
            </button>
          </div>
        </div>
      </div>
      <template #footer>
          <Button label="OK" @click="closeModal" autofocus />
      </template>
    </Dialog>
  </DataTable>
  <Paginator v-model:first="offset"
        :rows="limit"
        :totalRecords="respuestas.count"
        :rowsPerPageOptions="[2,10,20,30]"
        @page="onPage($event)"
  />
</div>
</template>

<script>
import respuestasStore from '../../../../../store/respuestas.store'
import { computed, onMounted, ref, watch } from 'vue'
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import Swal from 'sweetalert2'
// import { useRouter } from 'vue-router'
import PdfService from '@/apps/pharmasan/juridica/procesosJuridicos/services/pdf.service'
import UserSerivce from '@/apps/pharmasan/juridica/procesosJuridicos/services/usuarios.service'

export default {
  name: 'TablaRespuestas',
  setup () {
    // const router = useRouter()
    const _PdfService = new PdfService()
    const _UserService = new UserSerivce()
    const respuestas = computed(() => respuestasStore.getters.respuestas)
    const respuesta = computed(() => respuestasStore.getters.respuesta)
    const usuarios = ref([])
    const adjuntos = computed(() => respuestasStore.getters.respuesta.paths)
    const displayModal = ref(false)
    const offset = ref(0)
    const page = ref(0)
    const limit = ref(10)
    const tipos = [
      { name: 'Accion de Tutela', code: 1 },
      { name: 'Incidentes de Desacatos', code: 2 },
      { name: 'Fallos', code: 3 },
      { name: 'Respuestas', code: 4 },
      { name: 'Derechos de Petición', code: 5 },
      { name: 'Sanción', code: 6 },
      { name: 'Solicitudes', code: 7 },
      { name: 'Impugnación', code: 8 },
      { name: 'Procesos Judiciales', code: 9 },
      { name: 'Actuaciones', code: 11 }
    ]

    const verPdf = (path) => {
      _PdfService.ver({ path })
    }

    const openModal = (id) => {
      displayModal.value = true
      respuestasStore.dispatch('findRespuesta', id)
    }

    const closeModal = () => {
      displayModal.value = false
    }

    const params = computed(() => {
      return {
        offset: offset.value,
        page: page.value,
        limit: limit.value,
        filters: filters1.value

      }
    })

    const filters1 = ref({
      tipo: {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.EQUALS
          }
        ]
      },
      createdBy: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      }
    })
    const onPage = ({ first, page, pageCount, rows }) => {
      limit.value = rows
      respuestasStore.dispatch('getPaginate', params.value)
    }

    const remove = ({ id }) => {
      Swal.fire({
        title: 'Esta seguro?',
        text: 'Si se elimina este juzgado no se puede recuperar',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar!'
      }).then((result) => {
        if (result.isConfirmed) {
          respuestasStore.dispatch('removeRespuesta', id).then(async () => {
            await respuestasStore.dispatch('getPaginate', params.value)
            Swal.fire(
              'Eliminado!',
              'La respuesta fue eliminada con exito.',
              'success'
            )
          })
        }
      })
    }

    watch(filters1, (a, b) => {
      offset.value = 0
      page.value = 0
      limit.value = 10
      respuestasStore.dispatch('getPaginate', params.value)
    })

    onMounted(async () => {
      await respuestasStore.dispatch('getPaginate', params.value)
      await _UserService.getAll().then(({ data }) => {
        usuarios.value = data
      })
    })

    return {
      respuestas,
      filters1,
      onPage,
      remove,
      verPdf,
      usuarios,
      openModal,
      closeModal,
      displayModal,
      adjuntos,
      respuesta,
      offset,
      page,
      limit,
      tipos
    }
  }
}
</script>

<style scoped>
  ::v-deep(.pi) {
    font-size: 0.8rem;
}
  ::v-deep(.p-column-header-content) {
    justify-content: center;
}
</style>
