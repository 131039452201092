import { createStore } from 'vuex'
import RespuestasService from '../services/respuestas.service'

const _RespuestasService = new RespuestasService()
const storeRespuestas = {
  state: {
    _respuestas: [],
    _respuesta: {}
  },
  getters: {
    respuestas: state => state._respuestas,
    respuesta: state => state._respuesta
  },
  mutations: {
    setRespuestas (state, payload) {
      state._respuestas = payload
    },
    setRespuesta (state, payload) {
      state._respuesta = payload
    },
    setUpdateRespuesta (state, payload) {
      state._respuesta.nombre = payload.nombre
      state._respuesta.categoriaId = payload.categoriaId
      state._respuesta.code = payload.code
      state._respuesta.departamentoId = payload.departamentoId
      state._respuesta.municipioId = payload.municipioId
    },
    setDefault (state) {
      state._respuesta = {}
    }
  },
  actions: {
    getPaginate ({ commit }, payload) {
      _RespuestasService.paginate(payload).then(({ data }) => {
        commit('setRespuestas', data)
      })
    },
    createRespuesta ({ commit }, payload) {
        return _RespuestasService.save(payload).then((response) => {
            return response
        })
    },
    findRespuesta ({ commit }, id) {
      return new Promise((resolve, reject) => {
        _RespuestasService.find(id).then(({ data }) => {
          commit('setRespuesta', data)
          resolve(data)
        })
      })
    },
    updateRespuesta ({ getters }) {
      return new Promise((resolve, reject) => {
        const id = getters.respuestas.id
        _RespuestasService.edit(getters.respuestas, id).then(({ data }) => {
          resolve(data)
        })
      })
    },
    updateRespuestaNew ({ commit }, payload) {
      const { id } = payload
      delete payload.id
      return _RespuestasService.edit(payload, id).then((response) => {
        return response
      })
    },
    removeRespuesta ({ commit }, id) {
      return _RespuestasService.remove(id).then((response) => {
        return response
      })
    },
    removePdf ({ commit }, id) {
      return _RespuestasService.removePdf(id).then((response) => {
        return response
      })
    }
  }
}
const store = createStore(storeRespuestas)
export default {
  namespaced: true,
  ...store
}
