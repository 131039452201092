import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_PROCESOS_JURIDICOS

export default class RespuestasService {
  search (params) {
    return http.get(`${baseUrl}/respuestas/search`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  paginate (params) {
    return http.get(`${baseUrl}/respuestas/paginate`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  save (payload) {
    return http.post(`${baseUrl}/respuestas`, payload, {
      headers: {
        loading: true
      }
    })
  }

  find (id) {
    return http.get(`${baseUrl}/respuestas/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  edit (payload, id) {
    return http.put(`${baseUrl}/respuestas/${id}`, payload, {
      headers: {
        loading: true
      }
    })
  }

  remove (id) {
    return http.delete(`${baseUrl}/respuestas/${id}`, {
      headers: {
        loading: true
      }
     })
  }

  removePdf (id) {
    return http.delete(`${baseUrl}/pdf/${id}`, {
      headers: {
        loading: true
      }
    })
  }
}
